<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-sm-12"><h1>Become a traveller</h1></div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h3 class="text-muted">
          This page is building. You can become a traveller by using our mobile app
        </h3>
      </div>
    </div>
    <div class="row g-1">
      <div class="col-6">
        <a
            class="w-100 h-100"
            href="https://play.google.com/store/apps/details?id=com.trepr"
            target="_blank"
            rel="noopener"
        >
          <img
              src="/img/home/google-play-badge.png"
              class="img-fluid w-100 h-100"
              alt="google play"
          />
        </a>
      </div>
      <div class="col-6">
        <a
            class="w-100 h-100"
            href="https://apps.apple.com/us/app/trepr/id1504489267?itsct=apps_box_link&itscg=30200"
            target="_blank"
            rel="noopener"
        >
          <img src="/img/home/app-store-badge.svg" class="img-fluid w-100 h-100" alt="app store" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BecomeATraveller',
};
</script>

<style scoped></style>
